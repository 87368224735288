.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.row-header{
    margin:0px auto;
    padding:0px auto;
}

.row-content {
    margin:0px auto;
    padding: 50px 0px 50px 0px;
    border-bottom: 1px ridge;
    min-height:400px;
}

.footer{
    background-color: #D1C4E9;
    margin:0px auto;
    padding: 20px 0px 20px 0px;
}
.jumbotron {
    padding:70px 30px 70px 30px;
    margin:0px auto;
    background: #9575CD ;
    color:floralwhite;
}

address{
    font-size:80%;
    margin:0px;
    color:#0f0f0f;
}

.navbar-dark {
    background-color: #512DA8;
}

.page-enter {
    opacity: 0.01;
    transform: translateX(-100%);
}

.page-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: all 300ms ease-in;
}

.page-exit {
    opacity: 1;
    transform: translateX(0%);
}

.page-exit-active {
    opacity: 0.01;
    transform: translateX(100%);
    transition: all 300ms ease-out;
}
